import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 100,
        height: 100,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1024 1024">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={"#50C3CB"} />
            <stop offset="100%" stopColor={"#54C4CC"} />
          </linearGradient>

          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={"#B860B0"} />
            <stop offset="100%" stopColor={"#B860B0"} />
          </linearGradient>

            <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0%" stopColor={"#FFFFFF"} />
                <stop offset="100%" stopColor={"#FFFFFF"} />
            </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
            <path fill="url(#BG2)" d="M508,171.6c1.9,0,3.9,0,5.9,0c12,0,22.6,1.3,33.9,4.9c1.6,0.4,3.1,1,4.7,1.4c2.3,2.3,2.3,2.3,4.8,5.2
	c4.2,4.6,8.4,8.3,13.4,12.2c6.2,5,8.9,8.4,11.2,15.6c0.9,2.4,0.9,2.4,1.9,4.9c0.6,1.9,0.6,1.9,1.2,3.7c1.1,0,2,0,3.1,0
	c7.5,16.9,8.4,32.5,3.1,50.1c-1.1,0-2,0-3.1,0c-0.3,1.1-0.6,2.3-0.9,3.6c-0.5,1.6-0.8,3.1-1.2,4.7c-0.3,1.6-0.8,3-1.1,4.6
	c-1.4,4.3-3.1,6.6-6.1,10c-2.6,6.6-4.7,12-1.6,18.6c3.9,2.6,7.9,4.7,12.1,6.7c1.1,0.6,2.2,1.3,3.4,1.9c0,1,0,1.9,0,2.9
	c1.2,0.3,2.6,0.6,3.9,0.9c5.9,2.1,10,4.9,14.9,8.4c3.3,2.3,3.3,2.3,7.9,4.2c4.5,2.3,6.7,4.7,9.8,8.4c3.7,3.7,8.2,6,12.8,8.6
	c3.6,2.3,6.4,4.7,9.3,7.6c4,4,7.3,5.6,12.8,7.4c2.8,2,5.3,4,7.8,6.2c3.3,2.7,6.7,5.2,10.3,7.6c1.2,0.9,2.3,1.7,3.6,2.6
	c1.1,0.7,2.2,1.6,3.3,2.3c7.6,5.4,7.6,5.4,9.3,8.6c1.1,0.4,2.2,0.7,3.4,1.1c5.6,2.1,9.6,5.3,14.2,8.9c0.8,0.7,1.7,1.3,2.6,2
	c2.2,1.7,4.2,3.4,6.2,5.2c0,1,0,1.9,0,2.9c0.9,0.4,1.9,0.7,2.8,1.1c8.4,4.2,15.7,11,22.1,17.5c0,1,0,1.9,0,2.9
	c1.4,0.3,1.4,0.3,2.8,0.4c4,1.1,6.5,2.7,9.6,5.3c0,1,0,1.9,0,2.9c1.9,0.3,1.9,0.3,3.7,0.6c1.2,0.3,2.6,0.6,4,0.9
	c0.5,1,1.1,1.9,1.6,2.9c1.1,0.3,2.2,0.6,3.4,0.9c6.4,2.9,10.6,7.6,13.7,13.5c-0.2,4.3-1.4,5.6-4.7,8.6c-2.5,1.9-5,3.7-7.6,5.6
	c-3.6,3.3-4.7,5.9-6.4,10.2c-3.6,3.6-6.4,6.6-11.2,8.7c-3.7,1.7-4.2,3.6-5.9,7c-2,2.4-4,4.9-6.2,7.2c-0.8,1.1-1.7,2.1-2.5,3.3
	c-0.8,0.9-1.4,1.6-2.2,2.4c-1.1,0-2,0-3.1,0c-0.5,1.6-0.5,1.6-1.1,3.1c-2.5,4.9-5.4,7.7-9.8,11.2c-1.1,0-2,0-3.1,0
	c-0.6,2-0.6,2-1.2,4c-2,4.9-3.9,7.2-8.1,10.3c-1.1,0-2,0-3.1,0c-0.5,1.1-0.5,1.1-1.1,2.4c-2.6,4.2-6.1,7-9.8,10.4
	c-0.8,1-1.6,2-2.3,3c-2.3,2.7-2.3,2.7-5.9,4.4c-4.2,2.4-5.8,4.3-8.6,7.9c-4.2,5.4-8.9,9.6-14.5,13.7c-3.9,3-7.3,6.2-10.7,9.4
	c-1.1,1-2,1.9-3.1,2.9c-0.5,1.1-0.9,2.1-1.2,3.3c-2.2,4.6-5,7-8.9,10.4c-7,6.3-13.2,12.7-19.1,19.8c-4.7,5.2-9.5,9.7-15.2,13.7
	c-3.7,2.9-4.7,4.6-6.7,8.4c-1.2,1.1-2.6,2.1-3.9,3.3c-4.7,4.7-5.6,7.7-7,13.9c1.1,0,2.2,0,3.4,0c2.2,0,2.2,0,4.4,0
	c1.4,0,2.8,0,4.4,0c3.6,0.1,3.6,0.1,5.1,1.6c0.2,2.3,0.2,4.4,0.2,6.7c0,1.4,0,2.9,0,4.3c0,1.6,0,3.1,0,4.7c0,2.4,0,2.4,0,4.9
	c0,11.7-0.3,23.5-0.9,35.2c-0.2,2.3-0.2,2.3-0.2,4.7c-0.2,2.1-0.2,2.1-0.2,4.3c-0.2,1.9-0.2,1.9-0.2,3.9c-0.3,2.9-0.3,2.9-1.9,4.3
	c-0.3,2-0.5,3.9-0.5,5.9c-0.2,1.3-0.2,2.6-0.3,3.9c-0.2,1.4-0.2,2.7-0.3,4.2c-0.2,3-0.3,6-0.6,9c-0.2,2.4-0.2,2.4-0.3,4.7
	c-1.6,24.9-3.1,49.8-5.3,74.7c-0.2,1.1-0.2,2.3-0.3,3.4c-0.3,3.1-0.6,6.3-0.8,9.4c-0.3,2.6-0.3,2.6-0.5,5.3c-0.5,4.4-0.5,4.4-2,7.3
	c-0.3,5-0.6,10-0.8,15c-0.3,6.4-1.6,11.7-3.9,17.9c-0.3,3.1-0.6,6.3-0.8,9.4c-0.3,6.3-1.1,12.2-2.2,18.5c-1.7,9-2.6,17.9-3.6,26.9
	c-0.2,1.6-0.3,3.1-0.5,4.9c-0.6,6.7-1.4,13.5-2,20.2c-0.5,5-1.1,9.9-1.6,14.9c-0.2,1.6-0.3,3-0.5,4.7c-0.2,1.4-0.3,2.9-0.5,4.3
	c-0.2,1.3-0.3,2.4-0.3,3.7c-0.5,2.9-0.5,2.9-2,4.3c-2.6,0-5.1,0-7.8,0c-0.5-5.2-1.1-10.4-1.6-15.7c-1.1,0-2,0-3.1,0
	c-0.3-2-0.3-2-0.8-4c-0.9-5-2-10-3-15.2c-0.5-2.1-0.8-4.3-1.2-6.4c-2.3-12.2-4.8-24.2-8.1-36.2c-0.9-4.3-0.9-8.3-0.8-12.6
	c-1.1,0-2,0-3.1,0c-0.3-1.3-0.5-2.6-0.8-4c-2.3-11.6-5-23-7.9-34.5c-2.6-10-5-19.9-5.3-30.3c-1.1,0-2,0-3.1,0
	c-0.3-1-0.5-2.1-0.8-3.3c-1.1-4.4-2-8.9-3.1-13.3c-0.6-2.4-1.1-4.9-1.7-7.4c-0.9-4.3-2-8.6-3-12.7c-0.3-1.3-0.6-2.7-0.9-4.2
	c-1.9-8.2-4.2-16.3-6.7-24.3c-1.2-3.4-1.2-3.4-1.2-9.2c-1.1,0-2,0-3.1,0c-0.5-1-1.1-1.9-1.6-2.9c-1.6-0.4-3.1-1-4.7-1.4
	c-0.3,0.7-0.6,1.6-0.9,2.4c-2.8,7.7-5.6,15.6-8.4,23.3c-1.1,0-2,0-3.1,0c0,1.4,0.2,2.7,0.2,4.2c0,9.4-3,17.9-6.7,26.6
	c-2.3,6.6-3.6,13.6-4.8,20.5c-1.1,4.6-1.1,4.6-2.6,7.4c-1.6,0-3.1,0-4.7,0c-0.2,1.9-0.3,3.7-0.6,5.6c-0.3,2.4-0.5,4.9-0.8,7.4
	c-0.2,1.1-0.3,2.4-0.3,3.7c-0.8,7.2-2.2,13.6-4.5,20.6c-1.1,3.7-2.2,7.6-3.3,11.3c-0.3,1-0.5,1.9-0.8,2.9c-1.9,6.7-3,13.3-3.7,20
	c-1.1,0-2,0-3.1,0c-0.2,1.3-0.3,2.4-0.5,3.7c-2,14.7-5.1,29.1-8.9,43.5c-1.1,0-2,0-3.1,0c0.2,1.3,0.2,2.6,0.3,4
	c0,6.6-2,12.7-3.9,19.2c-1.2,4.7-2.2,9.3-2.6,14.2c-2,0.4-4,1-6.2,1.4c-4.5-7.2-5.8-13.3-6.5-21.5c-0.2-1.1-0.3-2.4-0.3-3.6
	c-0.9-10.3-1.2-20.5-0.9-30.8c-1.1,0-2,0-3.1,0c0-1.1-0.2-2.4-0.2-3.6c-0.2-5.3-0.3-10.7-0.6-16c0-1.9-0.2-3.7-0.2-5.6
	c0-1.9-0.2-3.6-0.2-5.4c0-1.7-0.2-3.3-0.2-5c-0.2-4.2-0.2-4.2-1.1-7c-1.2-4.7-1.2-9.6-1.6-14.3c0-1.1-0.2-2.1-0.2-3.3
	c-0.3-4.7-0.5-9.3-0.8-14c-0.2-3.4-0.3-6.9-0.6-10.3c0-1-0.2-2.1-0.2-3.1c-0.3-4.4-0.6-8-2.2-12.3c-0.3-4-0.6-7.9-0.8-11.9
	c0-1.1-0.2-2.3-0.2-3.6c-0.2-3.7-0.5-7.6-0.6-11.3c-0.3-4.9-0.5-9.9-0.8-14.7c0-1.1-0.2-2.4-0.2-3.7c-0.5-7.6-0.9-15.2-1.4-22.6
	c-0.6-8.4-0.9-16.9-1.2-25.5c-0.6-21-2.2-42.1-4.2-63c-3.4-35.1-3.4-35.1,1.6-42.7c4,0,8.2,0,12.4,0c-3-7.3-6.7-12.7-11.8-18.8
	c-2.2-2.7-2.2-2.7-4.7-6.7c-2.8-3.9-5.6-5.2-10-7.4c-7.3-6-7.3-6-9-9.9c-3.1-5.2-8.6-8-13.7-11.5c-3.9-3.1-5.9-6-8.4-10.2
	c-2.5-2.4-2.5-2.4-5-4.6c-5.3-4.7-9.8-9.9-14.2-15.3c-3.1-3.7-6.5-6.9-10.3-10.2c-2.2-2-4.5-4-6.7-6c-1.1-1-2.2-1.9-3.3-2.9
	c-2.6-2.9-2.6-2.9-2.6-5.7c-0.9-0.3-1.9-0.7-2.8-1c-4.4-2.3-6.4-5-9.5-8.6c-4.7-5.4-9.2-9.9-15.1-14.2c-3.6-3-5.9-6.7-8.6-10.6
	c-1.7-1.1-1.7-1.1-3.4-2.3c-2.8-2-2.8-2-3.4-4.9c-0.6-3.1-0.6-3.1-4.2-4.6c-5.4-3-8.7-6.7-12.8-11.2c-4.4-4.7-9.2-8.9-14-13.2
	c-3.7-3.4-7.3-7-10.9-10.6c-1.1-1.1-2.2-2.1-3.3-3.3c-2.5-2.9-2.5-2.9-2.5-5.7c-1.1,0-2,0-3.1,0c1.2-8.4,5.8-13,12.4-18.6
	c3.9-2.4,7.9-4.6,12.1-6.6c4.4-2.4,6.7-5.3,9.6-9.2c6.8-6,14-11,21.8-15.7c8.7-6.2,17.1-12.6,25.2-19.5c2.8-2.1,5.6-3.6,9-4.9
	c0-1,0-1.9,0-2.9c2.6-1.9,5.3-3.7,8.1-5.4c4.8-3.1,9.2-6,13.4-9.9c3.7-3.4,7.2-5.3,11.8-7.6c3.9-2,7-4.6,10.4-7.2
	c5.9-4.3,5.9-4.3,9.3-4.3c0.5-1.4,1.1-2.9,1.6-4.3c3.1-1.9,6.2-3.9,9.3-5.7c0.8-2.1,0.8-2.1,1.6-4.3c2.6-1,5.1-2.1,7.8-3
	c4.4-1.7,7.8-4.3,11.5-6.9c2.5-1.6,2.5-1.6,7.2-3c2.6-2.3,5.3-4.7,7.8-7.2c6.4-5.7,12-9.3,20.4-11.9c4.2-1.6,7.3-4,10.7-6.7
	c0-3.4,0-3.4-1.6-7.2c-3.1-2-6.2-3.9-9.3-5.7c-15.2-16-14.5-40.1-13.1-60c2-15.9,11.8-31.6,24.9-41.9c1.2-0.9,2.5-1.7,3.7-2.6
	c0.9-1,1.9-1.9,2.8-2.9C468.8,172.8,489.2,171.5,508,171.6z"/>
            <path fill="url(#BG1)" d="M825,508.4c1.7,0,3.4,0,5.3,0c2.8,0,2.8,0,5.8,0c2,0,4,0,6.1,0c5.4,0,11,0,16.5,0c5.8,0,11.5,0,17.3,0
	c9.6,0,19.3,0,28.9,0c11.2,0,22.4,0,33.5,0c9.6,0,19.1,0,28.8,0c5.8,0,11.5,0,17.1,0c6.4,0,12.8,0,19.1,0c1.9,0,3.9,0,5.8,0
	c2.6,0,2.6,0,5.3,0c2.3,0,2.3,0,4.5,0c3.4,0.1,3.4,0.1,5,1.6c0.2,2.1,0.2,4.3,0.2,6.4c0,1.3,0,2.6,0,3.9c0,1.3,0,2.7,0,4.2
	c0,1.3,0,2.7,0,4.2c0,3.4,0,6.7,0,10.2c-1.1,0-2,0-3.1,0c-0.6,1.3-0.6,1.3-1.2,2.6c-3.3,5.4-8.2,7.9-14.3,10.3
	c-6.2,1-12.3,0.9-18.7,0.9c-1.9,0-3.7,0-5.6,0c-6.1,0-12.1,0-18-0.1c-4.2,0-8.4,0-12.6,0c-8.7,0-17.6,0-26.3-0.1
	c-12.4-0.1-24.7-0.1-37.2-0.1c-8.6,0-17.1,0-25.8-0.1c-2,0-4.2,0-6.2,0c-6.8,0-13.7,0-20.5-0.1c-1.7,0-3.4,0-5.3,0
	c-1.6,0-3.1,0-4.8,0c-1.4,0-2.8,0-4.2,0c-3.1-0.1-3.1-0.1-4.7-1.6c-0.2-3-0.2-5.9-0.2-8.9c0-1.9,0-3.6,0-5.4c0-1,0-1.9,0-2.9
	c0-2.9,0-5.7,0-8.6c0-1.9,0-3.6,0-5.4c0-2.4,0-2.4,0-5C815.7,507.1,818.6,508.4,825,508.4z"/>
            <path fill="url(#BG1)" d="M107.5,215.1c10.1,1.1,16.3,6.4,23.3,12.9c1.2,0.7,2.5,1.4,3.9,2.1c4,2.3,6.7,4.7,10.1,7.9
	c3.1,1.9,6.2,3.9,9.3,5.7c3.4,2.4,6.8,4.9,10.3,7.3c4.8,3.6,9.8,7,14.6,10.4c3,2.3,3,2.3,5.1,4.3c4.7,4.2,10,7.6,15.1,11.2
	c6.5,4.6,13.1,9.3,17.6,15.7c2.5,3.1,2.5,3.1,7.5,4.2c9,3.1,14.8,11.5,20.1,18.5c1.4,3,1.4,3,1.6,5.7c-1.6,2.4-1.6,2.4-3.9,4.9
	c-0.9,0.9-1.7,1.7-2.6,2.6c-2.6,2.4-5.4,4.7-8.4,6.9c-5.4,4.2-8.2,8.4-11.5,14.3c-3.4,0.4-3.4,0.4-7.8,0c-3.9-3.1-7.3-6.6-10.7-10.2
	c-3.4-3-3.4-3-8.7-4.7c-5.9-2.4-8.9-5-13.4-9.4c-3.4-2.7-5.9-4.2-10.3-5.6c-4.5-1.9-5.6-3.7-8.4-7.3c-1.2-0.7-2.5-1.3-3.7-2
	c-4-2.3-4-2.3-7.9-6.6c-4-4.6-7.9-7-13.4-10.2c-1.9-1.4-3.7-2.9-5.6-4.4c-4.2-3.3-8.2-6-13.1-8.4c-9.2-5-17.4-11-25.7-17.3
	c-0.9-0.7-1.9-1.4-3-2.1c-4.2-3.3-6.8-5.3-8.7-10.2c-0.2-4.7,0.8-7.6,3-11.9c3.1-2.4,6.2-4.9,9.3-7.2c0.6-1,1.1-2.1,1.7-3.1
	c0.5-0.9,0.9-1.7,1.4-2.6c2-0.4,4.2-1,6.2-1.4c1.9-2.7,1.9-2.7,3.1-5.7C105.5,218,106.4,216.6,107.5,215.1z"/>
            <path fill="url(#BG1)" d="M5,508.4c1.6,0,3,0,4.5,0c1.7,0,3.4,0,5.1,0c1.9,0,3.7,0,5.6,0c2,0,3.9,0,5.9,0c5.4,0,10.7,0,16.2,0
	c5.6,0,11.2,0,16.8,0c10.6,0,21.3,0,31.9,0c12.1,0,24.3,0,36.4,0c24.9,0,49.9,0,74.8,0.1c1.7,4.6,1.7,7.7,1.7,12.5
	c0,1.6,0,3.1,0,4.9c0,1.7,0,3.3,0,5s0,3.3,0,5c0,2.4,0,2.4,0,4.9c0,1.4,0,2.9,0,4.4c0,3.4,0,3.4-1.6,4.9c-2.6,0.1-5.1,0.1-7.8,0.1
	c-2.5,0-2.5,0-5.1,0c-1.9,0-3.7,0-5.6,0c-2,0-3.9,0-5.9,0c-5.4,0-10.7,0-16.2,0c-5.6,0-11.2,0-16.8,0c-9.5,0-18.8,0-28.3,0
	c-10.9,0-21.8,0-32.8,0c-9.3,0-18.7,0-28,0c-5.6,0-11.2,0-16.8,0c-6.2,0-12.4,0-18.7,0c-2.8,0-2.8,0-5.6,0c-1.7,0-3.4,0-5.1,0
	c-1.4,0-3,0-4.5,0c-3.4-0.1-3.4-0.1-5-1.6C0,545.8,0,542.9,0,540.1c0-1.7,0-3.4,0-5.3c0-1.9,0-3.6,0-5.6c0-1.9,0-3.6,0-5.6
	c0-1.7,0-3.4,0-5.3c0-1.6,0-3.1,0-4.9C0.2,508.7,0.2,508.7,5,508.4z"/>
            <path fill="url(#BG1)" d="M905.7,169.3c3.4,3.7,3.4,3.7,6.4,8.7c0.5,0.9,1.1,1.7,1.6,2.6c1.6,2.4,3,4.9,4.5,7.3c1.4,2,2.6,3.9,4,5.9
	c2.2,4.2,2.2,4.2,2.3,7.3c-1.9,3-4.2,5.2-7,7.4c-4,4-7.2,8.7-10.4,13.3c-3.1,3.6-6.4,5.3-10.6,7.6c-0.8,1.4-1.4,2.7-2.2,4.2
	c-3,5.4-6.2,6.9-11.8,10.2c-2.8,2.4-5.4,5-8.2,7.6c-2.6,2.4-2.6,2.4-7.3,6c-3.6,2.9-6.4,5.3-9.3,8.6c-6.2,6.9-13.2,13-20.1,19.3
	c-4.2,3.9-8.4,7.7-12.1,12c-3.4,3.9-3.4,3.9-7.6,6.9c-3.9,2.6-3.9,2.6-5.9,6.7c-0.8,1-1.6,2.1-2.5,3.1c-4.8,0.1-4.8,0.1-9.3,0
	c-1.2-2.7-2.5-5.3-3.7-8c-0.6-1.4-1.4-3-2-4.6c-2-4.6-2-4.6-3.6-9.3c-1.2-4.3-1.2-4.3-6.2-6.7c-2-6.3-2.2-9,1.2-14.9
	c3.4-3.7,3.4-3.7,6.5-5.2c0.5-1.4,1.1-2.9,1.6-4.3c3-2.3,3-2.3,6.5-4.9c7.2-5,13.2-10.3,19-16.6c3.9-4.3,3.9-4.3,8.1-8
	c3.7-3,3.7-3,4.4-6.4c1.2-4.2,4.5-4.9,8.6-7c1.7-1.6,3.4-3.1,5.1-4.7c4.8-4.6,10-8.3,15.7-12c2.6-1.7,2.6-1.7,4-4.7
	c0.9-0.1,1.9-0.4,3-0.6c4.2-1,5.3-2.6,7.9-5.7c2.6-3,4.7-5.2,8.1-7.4c3.1-1.9,3.1-1.9,4.4-5.6C891.9,169.8,897.3,168.2,905.7,169.3z
	"/>
            <path fill="url(#BG1)" d="M497.1,2.7c1.6,0,3.1,0,4.8,0c2.5,0,2.5,0,5,0.1c1.7,0,3.3,0,5,0C524.2,3,524.2,3,526.1,4.7
	c0.2,2,0.2,3.9,0.2,5.9c0,1.9,0,1.9,0,3.9c0,1.4,0,2.9,0,4.3c0,1.4,0,3,0,4.4c0,4.9,0,9.7,0,14.7c0,3.4,0,6.7,0,10.2
	c0,7.2,0,14.2,0,21.3c0,9.2,0,18.3,0.2,27.3c0,7,0,14,0,21c0,3.4,0,6.7,0,10.2c0,4.7,0,9.4,0,14.2c0,1.4,0,2.9,0,4.3
	c0,1.3,0,2.6,0,3.9c0,1.1,0,2.3,0,3.4c-0.8,3.7-2,5-5.3,7.2c-3.9,0.6-3.9,0.6-8.6,0.4c-2.5,0-2.5,0-5,0c-1.7,0-3.4,0-5.3-0.1
	c-2.6,0-2.6,0-5.3,0c-12.9-0.1-12.9-0.1-14.8-1.7c-0.2-2-0.2-3.9-0.2-5.9c0-1.9,0-1.9,0-3.9c0-1.4,0-2.7,0-4.2c0-1.4,0-2.9,0-4.4
	c0-4.9,0-9.7,0-14.6c0-1.7,0-3.3,0-5c0-8.7,0-17.5,0-26.2c0-9,0-18-0.2-27.1c0-6.9,0-13.9,0-20.8c0-3.3,0-6.7,0-10c0-4.7,0-9.3,0-14
	c0-1.4,0-2.7,0-4.2c0.2-9.3,0.2-9.3,3.7-14C489.7,2.7,492.2,2.6,497.1,2.7z"/>
            <path fill="url(#BG1)" d="M827.9,711.8c4-0.3,4-0.3,7.8,0c0,1,0,1.9,0,2.9c1.7,0.1,1.7,0.1,3.4,0.4c5.3,1.3,7.9,3.3,12.1,6.6
	c1.9,1.4,1.9,1.4,3.9,3c1.9,1.4,1.9,1.4,3.9,3c1.4,1,3,2,4.4,3c1.1,0.9,2.2,1.7,3.4,2.7c0,1,0,1.9,0,2.9c1.1,0,2.2,0,3.3,0.1
	c5.8,1.7,7.5,4.3,11.2,8.6c4.5,4.9,9.8,8.7,15.1,12.9c2.6,2.1,5.4,4.3,8.1,6.4c2,1.6,4.2,3.3,6.2,4.9c1.1,0.9,2.3,1.7,3.4,2.7
	c1.6,1.3,1.6,1.3,3.3,2.4c2.3,2.1,2.3,2.1,2.3,5c2.3,0.4,2.3,0.4,4.8,0.9c6.4,1.7,9.2,4.9,13.2,9.6c2.3,2.9,2.3,2.9,6.7,5.6
	c3.3,2.7,3.3,2.7,3.9,5.9c-1.4,10-10.7,20.5-19.1,26.8c-2.8,1.1-2.8,1.1-6.2,1.4c-2.8,0.4-2.8,0.4-5.6,0.9
	c-8.7,0-14.2-5.4-20.4-10.4c-3.3-3-5-5.3-6.7-9.2c-1.1,0-2,0-3.1,0c-2-1.9-2-1.9-4.4-4.4c-0.8-0.9-1.7-1.9-2.5-2.7
	c-1.6-1.7-3-3.4-4.4-5.2c-4.2-5-8.1-7.6-14.2-10.4c-3.4-2-5.6-4.7-8.1-7.6c-4-4.3-9.2-7.4-14-11c-5.3-4-10.1-8.3-14.8-12.9
	c-1.4-1.3-2.8-2.6-4.4-4c-1.7-1.6-1.7-1.6-3.4-3.1c-0.9-0.7-1.9-1.3-2.8-2c-1.9-2.3-1.9-2.3-2.6-6c1.1-6.9,4.7-11.9,8.6-17.7
	c1.1,0,2,0,3.1,0c0.3-1.6,0.3-1.6,0.6-3.3C824.8,716.1,824.8,716.1,827.9,711.8z"/>
            <path fill="url(#BG1)" d="M169.8,729c3.3,2.7,5.9,5.3,8.7,8.3c1.4,1.6,2.8,3,4.4,4.6c0.6,0.7,1.4,1.4,2,2.3c2.3,2.4,2.3,2.4,6.5,5.2
	c3.4,2.6,3.4,2.6,3.9,6.4c-0.2,1-0.3,2.1-0.5,3.1c-1.4,0.1-1.4,0.1-3,0.3c-1.1,0.4-2.2,0.7-3.3,1.1c-0.6,1.6-0.6,1.6-1.2,3
	c-2.6,5.4-6.5,8-11.4,11.7c-3.1,2.3-3.1,2.3-6.1,5.3c-2.6,2.4-5.3,4.3-8.4,6.3c-6.2,4.3-12.1,8.9-18,13.5
	c-7.2,5.7-14.5,11.2-21.9,16.5c-3.1,2.1-5.9,4.4-8.9,6.7c-0.9,0.7-1.7,1.4-2.6,2.1c-2.2,1.7-4.4,3.6-6.5,5.4c-3.7,2.3-3.7,2.3-7.3,2
	c-10.9-3.1-21.2-12.6-27.5-21.2c-0.6-3.4-0.6-3.4,0-7.2c2.8-3.4,2.8-3.4,6.7-7c1.4-1.3,2.8-2.6,4.2-3.9c1.4-1.3,3-2.6,4.5-4
	c1.4-1.3,3-2.7,4.4-4c10.9-9.9,10.9-9.9,14.5-9.9c0.5-0.9,0.8-1.7,1.2-2.6c2-3.4,4-5.2,7.3-7.7c1.1-0.9,2.2-1.7,3.4-2.6
	c4-3.1,8.2-6.2,12.3-9.2c5.3-4,10.3-8,15.1-12.6c3.9-3.7,7.9-7.3,12.3-10.6c0.9-0.7,1.9-1.4,2.8-2.1
	C162.1,726.8,164.8,727.4,169.8,729z"/>
            <path fill="url(#BG3)" d="M390.7,462.3c1.6,0,3.3,0,4.8,0.1c3.7,0.3,7,0.9,10.7,1.7c2.3,12.3,3.6,24.3,3.7,36.9c0,1.7,0,3.3,0.2,5
	c0,3.4,0.2,6.9,0.2,10.3c0.2,5.3,0.2,10.6,0.3,15.9c0,3.3,0.2,6.7,0.2,10c0,1.6,0,3.1,0.2,4.9c0,1.4,0,2.9,0,4.4c0,1.3,0,2.6,0,3.9
	c0,3.1,0,3.1-1.6,6c-7.6-0.6-10.7-3.6-15.9-8.6c-4.8-4.9-4.8-4.9-10.6-8.6c0-1,0-1.9,0-2.9c-1.4-0.6-1.4-0.6-2.8-1.1
	c-3.9-2-5.4-3.9-7.9-7.3c-3.9-4.9-7.8-9-12.6-13.2c-19.9-17.9-19.9-17.9-20.7-23.5c0.5-3.7,0.5-3.7,3.3-6.2c1.1-0.7,2.3-1.4,3.4-2.1
	c4.8-2.7,4.8-2.7,7.9-6.9c1.6-0.6,3.3-1.1,4.8-1.7c8.1-3,14.2-8.2,19.8-14.2C382.5,461.9,385,462.2,390.7,462.3z"/>
            <path fill="url(#BG3)" d="M622.2,462.5c2.2,0.1,2.2,0.1,4.2,0.4c14.3,1.3,14.3,1.3,19.4,4.2c1.9,3.1,1.9,3.1,3.1,6.9
	c2.5,6.4,5.3,9.9,10.9,14.6c3.7,4.3,3.7,4.3,6.2,8.6c-1.7,5.2-4.5,9.6-7.6,14c-0.8,1.1-1.6,2.3-2.3,3.4c-0.8,0.9-1.6,1.7-2.3,2.6
	c-1.6,0-3.1,0-4.7,0c-0.3,0.9-0.8,1.6-1.2,2.4c-3.3,5.7-7,9.7-12.8,13.3c-1.6,2.4-3.1,4.7-4.7,7.2c-2.5,2.7-5.1,5.4-7.8,8.2
	c-1.4,1.4-2.6,2.7-4,4.2c-3.9,3.4-3.9,3.4-8.6,6.3c-1.6,0-3.1,0-4.7,0c-2.2-4-1.7-7.3-1.7-11.7c0-1.9,0-3.7,0-5.6c0-2,0-4.2,0-6.2
	c0-2.1,0-4.2,0-6.3c0-5.4,0-11,0-16.5c0-5.6,0-11.2,0-16.7c0-11,0-21.9,0-32.9C609.5,460.2,616.2,461.9,622.2,462.5z"/>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
